@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
}


:root {
  --backgroundColor: #2d4254;
  --normalTextColor: #1e3a4f;
  --yellowColor: #efc415;
  --grayColor: #cbd2d7;
  --textColorDark: #718390;
  --white: #fff;
  --redColor: #ff6363;
  --lightBlue: #e2edf8;
  --lightGray: #1e3a4f0d;
  --brightGray: #afb1b6;
  --darkGray: #19191B;
  --midBlue: #243A4A;
  --midGray : #808080;
  --lightGrayColor :#e0e0e0;
  --pitchColor :#fcd2d2;
  --lightRed : #eb5c64;
  --darkYellow: #d29a55;
  --skyBlue:#229dc7;
  --darkBlue: #072b50;
  --disabledFieldColor: #f4f4f4;
}