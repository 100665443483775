.flexibleDemand {
  width: 100%;
}
.flexibleDemand__breadcrumbs {
  color: var(--white);
    width: 40%;
    margin-right: auto;
    padding-left: 30px;
    display: flex;
    flex-wrap: wrap;
}
.flexibleDemand_Container {
  width: 600px;
  background: var(--white);
  border-radius: 16px;
  overflow-y: auto;
  padding: 40px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 30px;
}

.flexibleDemand_header img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.flexibleDemand_context {
  height: 72px;
  width: 520px;
  margin-top: 10px;
}
.flexibleDemand_context p {
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--normalTextColor);
}
.formContainer {
  width: 520px;
  position: relative;
}
.flexibleDemand_form {
  width: 520px;
  height: unset;
  border-radius: 16px;
  background-color: var(--lightGray);
  margin-top: 30px;
  padding: 20px;
}
.formHeading,
.scheduleHeading {
  height: 28px;
  width: 199px;
  font-family: inherit;
  font-size: 22px;
  font-weight: 900;
  line-height: 28px;
  letter-spacing: 0.004em;
  text-align: left;
  color: var(--yellowColor);
  margin-bottom: 30px;
}
.scheduleHeading {
  margin-top: 40px;
}
.forminput,
.flexibleDemand_capacity {
  width: 452px;
  height: 64px;
  background-color: var(--white);
  border-bottom: 2px solid var(--grayColor);
  margin-top: 20px;
  padding: 8px 12px 10px 12px;
}
.flexibleDemand_capacity_error,
.id__error {
  border-bottom: 2px solid var(--redColor);
}
.inputIdContainer {
  height: 46px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}
.inputIdContainer small,
.inputIdContainer span {
  height: 16px;
  width: 90%;
  font-family: inherit;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.6000000238418579px;
  text-align: left;
  color: var(--textColorDark);
}
.inputIdContainer span {
  width: 17px;
  height: 24px;
  position: absolute;
  right: 0px;
  bottom: 0;
}
.inputIdContainer input {
  height: 100%;
  width: 100%;
  border: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--normalTextColor);
  outline: none;
}
.inputIdContainer input::placeholder {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: var(--textColorDark);
}
.capacityHint {
  margin-top: 15px;
  height: 20px;
  width: 216px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07999999821186066px;
  text-align: left;
  color: var(--textColorDark);
}
.flexibleDemand_addBtn {
  width: 520px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.flexibleDemand_addBtn button {
  cursor: pointer;
  height: 56px;
  width: 202px;
  border-radius: 8px;
  padding: 16px 20px 16px 20px;
  border: 1px solid var(--normalTextColor);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: var(--normalTextColor);
}
.flexibleDemand_DatePicker,
.flexibleDemand_TimePicker {
  background: var(--white);
}
.schedule {
  height: unset;
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  align-items: "self-start";
}
.schedule > div:last-child {
  width: 40%;
  margin-left: 30px;
}

.flexibleDemand_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 30px;
}
.btn {
  width: 100px;
  height: 56px;
  border-radius: 8px;
  padding: 16px 20px 16px 20px;
  border: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  color: var(--normalTextColor);
  text-align: center;
  cursor: pointer;
}
.backBtn {
  background-color: var(--lightBlue);
}
.orderBtn {
  background-color: var(--yellowColor);
}
.orderBtn__disabled {
  opacity: 0.5;
}
.startTimeError,
.endTimeError,
.amountError,
.emptyInputError,
.isTimeEmpty,
.isIdEmpty {
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07999999821186066px;
  text-align: left;
  color: var(--redColor);
  width: 144px;
}
.isIdEmpty {
  position: absolute;
  bottom: -62%;
}
.endTimeError,
.isTimeEmpty {
  position: absolute;
  right: 9%;
  bottom: 27%;
}
.isTimeEmpty {
  bottom: 29%;
}
.sameDateTimeError {
  left: 4%;
  bottom: 29%;
  width: 100%;
}
.amountError {
  font-size: 14px;
  position: absolute;
  bottom: -117%;
  left: 0%;
}
.emptyInputError {
  font-size: 16px;
  margin: 0 auto;
  margin-top: 20px;
}

.inputGroup___wrapper {
  background-color: var(--white);
  padding-right: 12px;
}
.infoMessage{
  background:#d1e8f0;
  margin-top:10px;
  padding:3px;
  display:flex;
}
.infoIcon{
  color: #235461;
  position:relative;
  padding:2px;
}
.headerContainer{
  display: flex;
}
.crossIcon{
  width: 18%;
  display: flex;
  margin-top: 4px;
  justify-content: flex-end;
}