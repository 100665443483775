.moredemand {
  width: 100%;
  height: 150px;
  border-radius: 16px;
  background-color: var(--lightGray);
  border: 1px solid #efeff0;
  margin: 10px 0;
  padding: 20px 30px;
  position: relative;
  display: flex;
}
.moredemand_showAll {
  height: 251px;
}
.moredemand__left {
  width: 10%;
  padding-top: 10px;
}
.creditCard {
  font-size: 28px;
}
.moredemand__right {
  width: 90%;
  display: flex;
}
.moredemand__top {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 33.33%;
  font-family: inherit;
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0.11999999731779099px;
  text-align: left;
  color: var(--textColorDark);
}

.moredemand__top span {
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.11999999731779099px;
  color: var(--brightGray);
}
.moredemand__top h4 {
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
.editanddelete {
  color: var(--brightGray);
  display: flex;
  align-items: center;
  gap: 10px;
}
.moredemand__middle {
  width: 54%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--normalTextColor);
}
.moredemand__bottom {
  width: 18%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.moredemand__bottom h4 {
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
.moredemand__middle span {
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.11999999731779099px;
  color: var(--brightGray);
}
.moredemand__bottom img {
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.11999999731779099px;
  color: var(--brightGray);
  cursor: pointer;
}
.moredemand__top_all,
.moredemand__middle_all {
  height: 200px;
}
.line {
  width: 298px;
  border: 1px solid #d9dddf;
  position: absolute;
  top: 54%;
}
.tohidden {
  visibility: hidden;
}
.startTimeBold,
.endTimeBold {
  font-weight: 600;
  width: 230px;
}
.moredemand__middle small {
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--normalTextColor);
}
