.select__input_value {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 17px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  height: 100% !important;
  border: none !important;
  outline: none !important;
  top: 10px !important;
  color: #718290 !important;
  cursor: pointer !important;
}
