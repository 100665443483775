.inputGroup___MainWrapper {
  width: 100%;
}

.inputGroup___wrapper {
  border: none;
  outline: none;
  border-bottom: 2px solid var(--grayColor);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 12px 10px 0px;
  height: 66px;
}

.inputGroup___wrapper_withError {
  border-bottom: 2px solid var(--redColor);
}

.inputGroup__Input {
  height: 46px;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--normalTextColor);
  padding-left: 12px;
}

.inputGroup__Input::placeholder {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: left;
  color: var(--textColorDark);
}

.inputGroup__Input_withError {
  border-bottom: 2px solid var(--redColor);
}

.inputGroup__placeHolder {
  color: var(--textColorDark);
  font-size: 12px;
  line-height: 16px;
  z-index: 99;
  font-weight: 700;
  padding-left: 12px;
}

.inputGroup__placeHolder_withError {
  color: var(--redColor);
}

.inputGroup__Error {
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;
  color: var(--redColor);
  margin-top: 6px;
  display: block;
  padding-left: 12px;
}

.inputGroup__Unit_label {
  position: absolute;
  right: 12px;
  bottom: 7px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: left;
  color: var(--textColorDark);
}

.inputGroup__Unit_label_withWarning {
  right: 37px;
}

.inputGroup__warningIcon {
  width: 20px;
  height: 19px;
  position: absolute;
  right: 5px;
  bottom: 20px;
}

.input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input__Hint {
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07999999821186066px;
  text-align: left;
  color: var(--textColorDark);
  padding-left: 12px;
}

.inputGroup___wrapper>label {
  position: absolute;
  font-size: 12px;
  line-height: 16px;
  z-index: 99;
  font-weight: 700;
  padding-left: 12px;
  display: block;
}

.inputGroup___wrapper>input[type=text]:placeholder-shown+label {
  height: 46px;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--normalTextColor);
  padding-left: 12px;
  display: block;
  padding-top: 10px;
}

.first_letter {
  font-weight: 700;
  font-family: inherit;
  color: #718290;

}

.second_letter {
  font-weight: 700;
  font-family: inherit;
  color: #a9b2b7;
}
.input_disabled{
  pointer-events: none;
  background-color: var(--disabledFieldColor);
}