.btn , .firstBtn , .logoutbtn{
  text-decoration: none;
  width: 350px;
  margin-left: 27px;
  border-radius: 8px;
  padding: 16px 20px 16px 20px;
  background: transparent;
  color:var(--white);
  font-family: inherit;
  font-size: 24px;
  font-weight: 400;
  line-height: 8px;
  letter-spacing: 0.1599999964237213px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 20px;
}

.firstBtn {
  background: #efc415;
  color: #1e3a4f;
  font-weight: 700;
}

.firstBtn:after {
  content: '\2192';
  display: inline-block !important;
}

.btn:hover , .logoutbtn:hover{
  background: #efc415;
  color: #1e3a4f;
}

.logoutbtn {
  background: transparent;
  color: #efc415;
}

.arrowContainer {
  display: flex;
  text-align: end;
  justify-content: flex-end;
  height: 22px;
  width: 100px;
}

.home__btns {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 12px;
}
.icon_color{
  color: var(--white);
}