.header {
  width: 100%;
  height: 120px;
  padding: 30px;
  display: flex;
  justify-content: flex-end;
  top: 0;
  z-index: 99;
  align-items: center;
}
.header__logo {
  display: flex;
  gap: 20px;
}
.header__logo img {
  cursor: pointer;
}
.header__text {
  font-family: inherit;
  font-size: 22px;
  font-weight: 900;
  line-height: 28px;
  letter-spacing: 0.004em;
  text-align: left;
  color: var(--white);
}
.header__notificationIcon {
  width: 32px;
  height: 32px;
}
.header__notification {
  width: 40px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__notificationCount {
  display: inline-block;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: var(--white);
  background-color: var(--redColor);
  position: absolute;
  right: 1%;
  top: -11%;
}
.header__profileSection {
  display: flex;
  align-items: center;
  gap: 20px;
}
.header__profile {
  width: 149px;
  height: 40px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.profile__name {
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: var(--yellowColor);
  text-align: left;
}
.head{
  box-shadow: none !important;
  background-color: transparent;
  width: -webkit-fill-available;
  position: absolute;
}
.placeOrder__nextBtn {
  border-radius: 8px !important;
  background: var(--yellowColor) !important;
  color: var(--normalTextColor) !important;
  border: none !important;
  cursor: pointer !important;
  font-family: inherit !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.20000000298023224px !important;
}