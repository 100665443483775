.main_wrapper {
  position: relative;
}

.select__input {
  position: relative;
  padding: 8px 12px 10px 12px;
  border-bottom: 2px solid var(--grayColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select__input_error {
  border-bottom: 1px solid var(--redColor);
}

.select__input__textAndInput {
  width: 90%;
  height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.select__input__strokeImg {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
}

.select__input__small {
  color: var(--textColorDark);
  font-size: 12px;
  line-height: 16px;
  z-index: 99;
  font-weight: 700;
}

.select__input__smallError {
  color: var(--redColor);
}

.select__input__stroke {
  width: 16px;
  height: 9px;
  cursor: pointer;
}

.select__input_option {
  width: 100%;
  min-height: 10px;
  max-height: 196px;
  overflow-y: auto;
  background: var(--white);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 16px 16px;
  padding-left: 10px;
  position: absolute;
  z-index: 999;
}

.select__input_option ul {
  list-style: none;
  cursor: pointer;
}

.select__input_option ul li {
  padding: 8px 0px 8px 0px;
}

.select__input__option_error {
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07999999821186066px;
  text-align: left;
  color: var(--redColor);
}

.select__input_value {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  height: 100%;
  border: none;
  outline: none;
  color: var(--normalTextColor);
  cursor: pointer;
}

.select__input_value::placeholder {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: var(--textColorDark);
}

.select__input_value__error {
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07999999821186066px;
  text-align: left;
  color: var(--redColor);
}
.input_disabled{
  pointer-events: none;
  background-color: #f4f4f4;
}