.containerBefore {
  display: flex;
}
.firstBtn {
  text-decoration: none;
  margin-left: 27px;
  border-radius: 8px;
  padding: 16px 60px 16px 60px;
  background: #efc415;
  color: #1e3a4f;
  font-family: inherit;
  font-size: 24px;
  height: fit-content;
  border: none;
  font-weight: 400;
  letter-spacing: 0.1599999964237213px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 20px;
}
.firstBtn:hover {
  font-weight: 700;
}
.hideButton {
  display: none;
}
.header__profile {
  width: 149px;
  height: 40px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.profile__name {
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: var(--yellowColor);
  text-align: left;
}
.home_container {
  display: flex;
  width: 100%;
}
.logo {
  width: 33.33%;
  display: flex;
  align-items: center;
}
.img_logo {
  padding-left: 20px;
}
.btn {
  width: 33.33%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.img {
  height: 350px;
  margin: 129px 0px 0px 0px;
  width: 50%;
  position: fixed;
}
.backgroundImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}
.img_container {
  width: 100%;
}
